'use client';


import OrangeStarIcon from '@/components/ui/orangeStarIcon';
import LoginButton from '@/components/web3/LoginButton';
import { useRouter } from 'next/navigation'
import { useEffect } from "react";
import { useSIWE, type SIWESession } from "connectkit";

export default function Home() {

  const router = useRouter()


  const { data: session, isReady, isSignedIn } = useSIWE();



  useEffect(() => {
    async function checkSign() {
    router.prefetch("/create")
    if (isSignedIn && isSignedIn) {
        await router.push("/portfolio");
  }}
   checkSign();

  }, [isReady, isSignedIn, router, session]);


  if (typeof window !== 'undefined' && !(/Mobi|Android/i.test(window.navigator.userAgent))) {
    return (
      <div className="relative min-h-screen flex flex-col items-center justify-center text-white">
        <main className="relative z-10 flex flex-col items-center space-y-10">
          <OrangeStarIcon className="h-12 w-12 text-[#00baff]" />
          <div className="space-y-4">

            <h1 className="h1Style text-justify style={{ fontWeight: 'bold' }}">
              Unlock Creative Control of Your Voice
            </h1>
            <h2 className="h2Style" style={{ textAlign: 'center' }}>
              Let someone post on your Twitter once — without losing control. Teleport makes it easy with one-time-use links.             
            </h2>
          </div>

          <div className="space-y-4 flex justify-center">
            <h1 className="h2Style text-justify orangeColor" style={{ width: '500px', background: 'radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.02) 74.01%)', backdropFilter: 'blur(30px)' }}>
              Try Teleport on mobile for the best experience, open <span className='blueColor'>teleport.best</span> on your phone and click &quot;Add to Home Screen&quot; in browser options to access it like an app.
            </h1>
          </div>
          <LoginButton text="" />
        </main>
      </div>
    );
  } else {
    return (
     
      <div className="relative min-h-screen flex flex-col items-center justify-center text-white">
        <main className="relative z-10 flex flex-col items-center space-y-10">
          <OrangeStarIcon className="h-12 w-12 text-[#00baff]" />
          <div className="space-y-4">
            <h1 className="h1Style text-justify style={{ fontWeight: 'bold' }}">
              Unlock Creative Control of Your Voice
            </h1>
            <h2 className="h2Style mx-4 sm:mx-0" style={{ textAlign: 'center' }}>
              Let someone post on your Twitter once — without losing control. Teleport makes it easy with one-time-use links.             
            </h2>
          </div>
          {/* loginCard */}
          {/* w-[620px] h-[301px] shrink-0 [background:radial-gradient(100%_100%_at_50%_0%,rgba(255,255,255,0.12)_0%,rgba(255,255,255,0.02)_74.01%)] backdrop-blur-[30px] rounded-2xl border-[1.5px] border-solid border-[rgba(255,255,255,0.12)] */}
          <LoginButton text="" />
        </main>
      </div>
    );
  }
  
    
}